import Image from 'next/image'
import React from 'react'

export default function StyledImage({
  containerClass,
  src,
  width,
  height,
  alt,
  onClick,
}) {
  return (
    <div className={containerClass} onClick={onClick}>
      <Image src={src} width={width} height={height} alt={alt} />
    </div>
  )
}
