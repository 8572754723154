import * as Sentry from '@sentry/react'
import React from 'react'

import UnexpectedErrorDialog from './UnexpectedErrorDialog'

const ErrorHandlingContext = React.createContext()

export function ErrorHandlingProvider({ children, onNetworkError, onError }) {
  const [resets, setResets] = React.useState(0)

  const value = React.useMemo(
    () => ({
      handleError: onError,
      handleNetworkError: onNetworkError,
    }),
    [onError, onNetworkError]
  )

  return (
    <ErrorHandlingContext.Provider value={value}>
      <Sentry.ErrorBoundary
        fallback={function (fallbackProps) {
          const canReset = resets === 0
          const resetError = function () {
            if (!canReset) {
              return
            }

            setResets(resets + 1)
            fallbackProps.resetError()
          }
          const props = {
            ...fallbackProps,
            type: 'generic',
            open: true,
            isTerminalError: true,
            canResetError: canReset,
            resetError,
          }

          return <UnexpectedErrorDialog {...props} />
        }}
      >
        {children}
      </Sentry.ErrorBoundary>
    </ErrorHandlingContext.Provider>
  )
}

export function useErrorHandler() {
  return React.useContext(ErrorHandlingContext)
}
