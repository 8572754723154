import axios from 'axios'

const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}/webdm`

const Api = {
  getCategories: async () => {
    const response = await axios.get(`${apiUrl}/categories`)
    return response.data
  },
  getPages: async ({ slug }) => {
    const response = await axios.get(`${apiUrl}/pages?slug=${slug}`)
    return response.data
  },
  getNews: async ({ shouldHaveTips = false, offset = 0 }) => {
    const response = await axios.get(
      `${apiUrl}/news?offset=${offset}&tips=${shouldHaveTips}`
    )
    return response.data
  },
  getTips: async ({ offset = 0, language, search, categoryId }) => {
    const response = await axios.post(`${apiUrl}/tips?offset=${offset}`, {
      language,
      search,
      categoryId,
    })
    return response.data
  },
  findProduct: async ({ barcodes }) => {
    try {
      const response = await axios.post(`${apiUrl}/products`, {
        barcodes,
      })
      return response.data
    } catch (error) {
      if (error.response?.status === '404') {
        return null
      }

      throw error
    }
  },
}

export default Api
