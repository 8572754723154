import { Box, Typography, makeStyles } from '@material-ui/core'
import React from 'react'

import { useLanguageContext } from 'components/LanguageContext'
import SimpleDialog from '../SimpleDialog'

const useStyles = makeStyles({
  root: {
    padding: 40,
  },
})
export default React.forwardRef(function PrivacyPolicyDialog({}, ref) {
  const { i18n } = useLanguageContext()
  const classes = useStyles()
  const dialogRef = React.useRef()

  const open = React.useCallback(() => {
    dialogRef.current.open()
  }, [])

  React.useImperativeHandle(ref, () => ({
    open,
  }))

  return (
    <SimpleDialog ref={dialogRef}>
      <Box className={classes.root}>
        <Typography variant="h4">{i18n.privacyPolicy.content}</Typography>
      </Box>
    </SimpleDialog>
  )
})
