import { Parser as HtmlParser } from 'html-to-react'

const parser = new HtmlParser()

export default function parseHtml(html) {
  if (!html) {
    return null
  }

  const normalizedHtml = html
    .replace(/<style[\s\S]*?<\/style>/gi, '')
    .replace(/<html>/i, '<div>')
    .replace(/<\/html>/i, '</div>')
    .replace(/<head[\s\S]*?<\/head>/gim, '')
    .replaceAll(/<body>/gi, '<div>')
    .replaceAll(/<\/body>/gi, '</div>')
    .replaceAll(/>\s{2,}?</gi, '><')
    .replaceAll(/\r\n/gi, '<br/>')
    .replaceAll(/\n/gi, '<br/>')

  return parser.parse(normalizedHtml)
}
