export function dateStrToLocal(date) {
  if (!date) {
    return null
  }

  return new Intl.DateTimeFormat(navigator.language, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(new Date(date))
}

export function getNewsFeedTexts(newsFeedItem, language) {
  // fall back to English if text is not found for given language
  return (
    newsFeedItem.texts.find(x => x.language === language) ||
    newsFeedItem.texts.find(x => x.language === 'en')
  )
}
