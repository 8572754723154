import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { useQuery } from 'react-query'

import Api from 'api'
import { useLanguageContext } from 'components/LanguageContext'
import parseHtml from 'components/parseHtml'
import SimpleDialog from '../SimpleDialog'

const useStyles = makeStyles({
  root: {
    padding: 40,
  },
})

export default React.forwardRef(function AboutDialog({}, ref) {
  const { language } = useLanguageContext()
  const dialogRef = React.useRef()

  const { data } = useQuery({
    queryKey: ['pages', 'about'],
    queryFn: () => Api.getPages({ slug: 'about' }),
  })

  const page =
    data?.pages.find(x => x.language === language) ||
    data?.pages.find(x => x.language === 'en')

  const open = React.useCallback(() => {
    dialogRef.current.open()
  }, [])

  React.useImperativeHandle(ref, () => ({
    open,
  }))
  const classes = useStyles()

  return (
    <SimpleDialog ref={dialogRef}>
      <Box className={classes.root}>{page ? parseHtml(page.content) : ''}</Box>
    </SimpleDialog>
  )
})
