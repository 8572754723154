import { Box, Typography, makeStyles } from '@material-ui/core'
import { MoreHoriz as TipRoundIcon } from '@material-ui/icons'
import { useRouter } from 'next/router'
import React from 'react'

import { useDialog } from 'components/DialogsContext'
import { useLanguageContext } from 'components/LanguageContext'
import slugify from 'components/slugify'

const useStyles = makeStyles(theme => ({
  divider: {
    borderBottom: '1px solid #EDF3FC',
    width: '100%',
  },
  tipContent: {
    margin: '0 20px',
  },
  tipItemContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    minHeight: 60,
    [theme.breakpoints.down('xs')]: {
      height: 70,
    },
    [theme.breakpoints.down('375')]: {
      height: 80,
    },
  },
  tipIcon: {
    backgroundColor: ({ color }) => color,
    borderRadius: 15,
    color: 'white',
    height: 30,
    textAlign: 'center',
    width: 30,
  },
}))

export default function Tip({ id, color, showDivider, tip }) {
  const { openTipDialog } = useDialog()
  const { isSpanish, language } = useLanguageContext()
  const router = useRouter()

  const onTipPress = React.useCallback(() => {
    const title = isSpanish ? tip.tip_es : tip.tip

    router.push(`tips/${tip.id}/${slugify(title)}`, undefined, {
      locale: language,
      shallow: true,
    })

    openTipDialog({
      ...tip,
      color,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, color, isSpanish, openTipDialog, tip])

  const classes = useStyles({ color })

  return (
    <>
      <Box id={id} className={classes.tipItemContainer} onClick={onTipPress}>
        <TipRoundIcon className={classes.tipIcon} />
        <Typography
          variant="subtitle2"
          color="textPrimary"
          className={classes.tipContent}
        >
          {isSpanish ? tip.tip_es : tip.tip}
        </Typography>
      </Box>
      {showDivider && <Box className={classes.divider} />}
    </>
  )
}
