import { Box, Link, Typography, makeStyles } from '@material-ui/core'
import { Share as ShareFeedIcon } from '@material-ui/icons'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

import { useLanguageContext } from 'components/LanguageContext'
import ShareSocial from 'components/ShareSocial'
import slugify from 'components/slugify'
import SimpleDialog from '../SimpleDialog'
import Tip from '../Tip'
import { dateStrToLocal } from '../utils'
import IconCalendar from './assets/icon_calendar_large.png'
import IconLink from './assets/icon_link_large.png'
import parseHtml from 'components/parseHtml'

const useStyles = makeStyles(theme => ({
  contentText: {
    marginTop: 20,
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  headerItem: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      margin: '5px 0px',
    },
    [theme.breakpoints.down('400')]: {
      width: '100%',
    },
  },
  headerText: {
    marginLeft: 5,
    marginRight: 10,
    fontSize: 15,
  },
  divider: {
    borderBottom: '1px solid #EDF3FC',
    width: '100%',
  },
  readMorebButton: {
    'alignItems': 'center',
    'backgroundColor': theme.palette.primary.main,
    'borderRadius': 25,
    'color': 'white',
    'cursor': 'pointer',
    'display': 'flex',
    'height': 50,
    'justifyContent': 'center',
    'marginTop': 35,
    'marginBottom': 35,
    'width': 155,
    'transition': 'all 200ms',
    '&:hover': {
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
      transition: 'all 200ms',
    },
  },
  root: {
    overflowY: 'auto',
    padding: 20,
    [theme.breakpoints.down('380')]: {
      padding: 5,
    },
  },
}))

const truncateBody = str => {
  return str.length > 1400 ? str.substring(0, 1400) + '...' : str
}

const FeedDialog = React.forwardRef(({}, ref) => {
  const router = useRouter()
  const dialogRef = React.useRef()
  const { i18n, language } = useLanguageContext()
  const [feed, setFeed] = React.useState({})

  const open = React.useCallback(feed => {
    setFeed(feed)
    dialogRef.current.open()
  }, [])

  const close = React.useCallback(() => {
    router.push('/', undefined, {
      locale: language,
      shallow: true,
    })
    dialogRef.current.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  React.useImperativeHandle(ref, () => ({
    open,
    close,
  }))

  const shareUrl = React.useMemo(() => {
    if (!feed.id) {
      return ''
    }

    return `news/${feed.id}/${slugify(feed.title)}`
  }, [feed])

  const renderTip = React.useCallback(
    (tip, index) => (
      <Tip
        key={tip.id}
        color={tip.category.parentPrimaryColor}
        index={index}
        showDivider={index < feed.tips.length - 1}
        tip={tip}
      />
    ),
    [feed]
  )

  const classes = useStyles()
  const content = feed.body ?? feed.content

  return (
    <SimpleDialog ref={dialogRef} onClose={close}>
      <Box className={classes.root}>
        <Box className={classes.headerContainer}>
          <Box className={classes.headerItem}>
            <Image src={IconCalendar} alt="calendar-icon" />
            <Typography color="textPrimary" className={classes.headerText}>
              {dateStrToLocal(feed.feed_date)}
            </Typography>
          </Box>
          <Box className={classes.headerItem}>
            <Image src={IconLink} alt="link-icon" />
            <Typography className={classes.headerText} noWrap>
              <Link
                color="textPrimary"
                href={`http://${feed.source_url}`}
                target="_blank"
              >
                {feed.source_url}
              </Link>
            </Typography>
          </Box>
          <Box className={classes.headerItem}>
            <ShareSocial
              left={-10}
              top={10}
              shareText={feed.title}
              shareUrl={shareUrl}
            >
              <ShareFeedIcon color="primary" />
            </ShareSocial>
          </Box>
        </Box>
        <Typography variant="h2" color="textPrimary">
          {feed.texts && feed.title}
        </Typography>
        {Boolean(feed.texts && content) && (
          <Typography variant="h4" className={classes.contentText}>
            {parseHtml(truncateBody(content))}
          </Typography>
        )}
        <Link href={feed.feed_url} target="_blank">
          <Box id="readMore" className={classes.readMorebButton}>
            <Typography variant="h6">{i18n.newsFeed.readMore}</Typography>
          </Box>
        </Link>

        {feed.tips && feed.tips.length > 0 && (
          <>
            <Typography variant="h5" gutterBottom>
              {i18n.newsFeed.relatedTips}
            </Typography>
            {feed.tips.map(renderTip)}
          </>
        )}
      </Box>
    </SimpleDialog>
  )
})

FeedDialog.displayName = 'FeedDialog'

export default FeedDialog
