import * as Sentry from '@sentry/react'
import Head from 'next/head'
import Script from 'next/script'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import DialogsProvider from 'components/DialogsContext'
import {
  ErrorHandlingProvider,
  UnexpectedErrorDialog,
} from 'components/ErrorHandling'
import LanguageContextProvider from 'components/LanguageContext'
import TipFilterContextProvider from 'components/TipFilterContext'
import { BaselineProvider } from 'components/material-ui'
import theme from 'components/theme'

const queryClient = new QueryClient()

export default function App({ Component, pageProps }) {
  const errorDialog = React.useRef()

  const onNetworkError = React.useCallback(error => {
    const eventId = Sentry.captureException(error)

    errorDialog.current.open({
      type: !error.response ? 'network' : 'generic',
      eventId,
      error,
    })
  }, [])

  const onError = React.useCallback(
    (type, error) => {
      if (type === 'network') {
        onNetworkError(error)
        return
      }

      if (error.extensions) {
        Sentry.configureScope(scope => {
          scope.setExtra('extensions', error.extensions)
        })
      }

      const eventId = Sentry.captureException(
        error.extensions ? error.message : error
      )

      errorDialog.current.open({
        type: 'generic',
        eventId,
        error,
      })
    },
    [onNetworkError]
  )

  return (
    <BaselineProvider theme={theme}>
      <Head>
        <title>Detox Me - Silent Spring Institute</title>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta
          property="og:site_name"
          content="Detox Me - Silent Spring Institute"
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_BASE_URL}/detox_me.png`}
        />
        <meta
          property="og:image:secure_url"
          content={`${process.env.NEXT_PUBLIC_BASE_URL}/detox_me.png`}
        />
        <meta property="og:type" content="website" />
        <meta
          key="url"
          property="og:url"
          content={process.env.NEXT_PUBLIC_BASE_URL}
        />
        <meta
          key="title"
          property="og:title"
          content="Detox Me app: Tips for healthier living"
        />
        <meta
          key="description"
          property="og:description"
          content="Silent Spring’s Detox Me app is a clean lifestyle guide that walks you through simple, research-based tips on how to reduce your exposure to harmful chemicals where you live, work, and play."
        />

        <meta
          key="twitter:card"
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          key="twitter:title"
          name="twitter:title"
          content="Detox Me app: Tips for healthier living"
        />
        <meta
          key="twitter:description"
          name="twitter:description"
          content="Silent Spring’s Detox Me app is a clean lifestyle guide that walks you through simple, research-based tips on how to reduce your exposure to harmful chemicals where you live, work, and play."
        />
        <meta
          key="twitter:image"
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_BASE_URL}/detox_me.png`}
        />

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `var _ctct_m = "95e1e84b43eb342f326c1b34aa74520a"`,
          }}
        />
      </Head>

      <Script
        id="signupScript"
        src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js"
        strategy="lazyOnload"
      />

      <ErrorHandlingProvider onError={onError} onNetworkError={onNetworkError}>
        <LanguageContextProvider>
          <QueryClientProvider client={queryClient}>
            <DialogsProvider>
              <TipFilterContextProvider>
                <Component {...pageProps} />
              </TipFilterContextProvider>
            </DialogsProvider>
            <UnexpectedErrorDialog ref={errorDialog} />
          </QueryClientProvider>
        </LanguageContextProvider>
      </ErrorHandlingProvider>
    </BaselineProvider>
  )
}
