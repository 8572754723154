import {
  Box,
  Button,
  CircularProgress,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { useQuery } from 'react-query'

import Api from 'api'
import { useLanguageContext } from 'components/LanguageContext'
import BarcodeScanner from '../BarcodeScanner'
import Tip from '../Tip'

const useStyles = makeStyles(_ => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  scanAgainBtn: {
    margin: '15px 0px',
    alignSelf: 'center',
  },
  tipContainer: {
    padding: '20px 10px 10px 10px',
    alignSelf: 'flex-start',
  },
  loader: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#39393980',
    width: '100%',
    height: '100%',
  },
  alert: {
    borderRadius: 15,
    margin: '20px 0px 10px 0px',
  },
  caption: {
    textAlign: 'center',
    marginTop: 10,
    padding: 10,
  },
}))

const getCategoryId = product => {
  if (product && product.category) {
    return product.category.id ?? product.category.parentId
  }

  return null
}

const getBarCodePermutations = barcode => {
  if (barcode.length === 13) {
    return [barcode, `0${barcode}`]
  }

  return [barcode]
}

export default function ScanProduct() {
  const { i18n } = useLanguageContext()
  const barcodeScanner = React.useRef()
  const [mounted, setMounted] = React.useState(false)
  const [state, setState] = React.useState(null)
  const [barcode, setBarcode] = React.useState(null)

  const onBarcodeScannerInit = React.useCallback(() => {
    setMounted(true)
    setState(true)
  }, [])

  const onBarcodeScannerError = React.useCallback(error => {
    setState(error)
  }, [])

  const { isLoading: isLoadingProduct, data } = useQuery({
    queryKey: ['products', barcode],
    queryFn: () =>
      Api.findProduct({
        barcodes: getBarCodePermutations(barcode),
      }),
    enabled: !!barcode,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const { isLoading: isLoadingTips, data: tipsData } = useQuery({
    queryKey: ['tips', getCategoryId(data?.product)],
    queryFn: () => Api.getTips({ categoryId: getCategoryId(data?.product) }),
    enabled: !!data?.product,
    refetchOnWindowFocus: false,
  })

  const onScanDetected = React.useCallback(data => {
    const { codeResult } = data
    if (codeResult && codeResult.code) {
      setBarcode(codeResult.code)
      barcodeScanner.current?.pause()
    }
  }, [])

  const onScanAgain = React.useCallback(() => {
    setBarcode(null)
    barcodeScanner.current.start()
  }, [])

  const classes = useStyles()

  const cameraPermissionStatus = React.useMemo(() => {
    if (state === null && mounted) {
      return <p className={classes.caption}>{i18n.scanner.permissionCamera}</p>
    }

    if (state instanceof Error) {
      return (
        <p className={classes.caption}>
          {i18n.scanner.errorPermissionCamera} {state.message}.
        </p>
      )
    }

    return null
  }, [mounted, state, classes, i18n])

  const hasTips = React.useMemo(
    () => tipsData && tipsData.items.length > 0,
    [tipsData]
  )

  const renderResults = React.useCallback(() => {
    if (isLoadingProduct) {
      return null
    }

    if (!data?.product && !!barcode) {
      return (
        <>
          <Typography variant="h5" gutterBottom className={classes.caption}>
            {i18n.scanner.noProductsFound} {barcode}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={onScanAgain}
            className={classes.scanAgainBtn}
          >
            {i18n.scanner.scanAgainBtn}
          </Button>
        </>
      )
    }

    if (hasTips) {
      return (
        <>
          <Box className={classes.tipContainer}>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              className={classes.caption}
            >
              {i18n.scanner.relatedTips} {data.product.name}
            </Typography>
            {tipsData.items.map((tip, index) => (
              <Tip
                key={tip.id}
                tip={tip}
                color={tip.category.parentPrimaryColor}
                index={index}
                showDivider={index < tipsData.items.length - 1}
              />
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={onScanAgain}
            className={classes.scanAgainBtn}
          >
            {i18n.scanner.scanAgainBtn}
          </Button>
        </>
      )
    }

    return null
  }, [
    isLoadingProduct,
    barcode,
    hasTips,
    classes,
    data,
    tipsData,
    i18n,
    onScanAgain,
  ])

  const hasError = state instanceof Error

  return (
    <Box className={classes.root}>
      {cameraPermissionStatus}

      {renderResults()}

      <BarcodeScanner
        ref={barcodeScanner}
        onDetected={onScanDetected}
        onInitialize={onBarcodeScannerInit}
        onError={onBarcodeScannerError}
        hidden={hasTips}
      />

      {!hasError && !hasTips && (
        <>
          <Typography variant="h6" className={classes.caption}>
            {i18n.scanner.scanHelpText}
          </Typography>
          <Alert severity="info" className={classes.alert}>
            {i18n.scanner.noSpecificTips}
          </Alert>
        </>
      )}

      {(isLoadingProduct || isLoadingTips) && (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
