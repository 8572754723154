import React from 'react'

import AboutDialog from './AboutDialog'
import CategoryDialog from './CategoryDialog'
import ContactDialog from './ContactDialog'
import DownloadAppDialog from './DownloadAppDialog'
import FeedDialog from './FeedDialog'
import PrivacyPolicyDialog from './PrivacyPolicyDialog'
import ScanProductDialog from './ScanProduct/ScanProductDialog'
import SubscriptionDialog from './SubscriptionDialog'
import TipDialog from './TipDialog'
import TipTermDialog from './TipTermDialog'

export const DialogsContext = React.createContext()

export default function DialogsProvider({ children }) {
  const aboutDialog = React.useRef()
  const categoryDialog = React.useRef()
  const tipDialog = React.useRef()
  const feedDialog = React.useRef()
  const privacyPolicyDialog = React.useRef()
  const scanProductDialog = React.useRef()
  const subscriptionDialog = React.useRef()
  const tipTermDialog = React.useRef()
  const downloadAppDialog = React.useRef()
  const contactDialog = React.useRef()

  const openAboutDialog = React.useCallback(() => {
    aboutDialog.current.open()
  }, [])

  const openCategoryDialog = React.useCallback(category => {
    categoryDialog.current.open(category)
  }, [])

  const openTipDialog = React.useCallback(tip => {
    tipDialog.current.open(tip)
  }, [])

  const openFeedDialog = React.useCallback(feed => {
    feedDialog.current.open(feed)
  }, [])

  const closeFeedDialog = React.useCallback(() => {
    feedDialog.current.close()
  }, [])

  const openPrivacyPolicyDialog = React.useCallback(() => {
    privacyPolicyDialog.current.open()
  }, [])

  const openScanProductDialog = React.useCallback(() => {
    scanProductDialog.current.open()
  }, [])

  const openSubscriptionDialog = React.useCallback(() => {
    subscriptionDialog.current.open()
  }, [])

  const openTipTermDialog = React.useCallback(term => {
    tipTermDialog.current.open(term)
  }, [])

  const openDownloadAppDialog = React.useCallback(addonTexts => {
    downloadAppDialog.current.open(addonTexts)
  }, [])

  const openContactDialog = React.useCallback(() => {
    contactDialog.current.open()
  }, [])

  const value = React.useMemo(
    () => ({
      closeFeedDialog,
      openAboutDialog,
      openCategoryDialog,
      openTipDialog,
      openFeedDialog,
      openPrivacyPolicyDialog,
      openScanProductDialog,
      openSubscriptionDialog,
      openTipTermDialog,
      openDownloadAppDialog,
      openContactDialog,
    }),
    [
      closeFeedDialog,
      openAboutDialog,
      openCategoryDialog,
      openTipDialog,
      openFeedDialog,
      openPrivacyPolicyDialog,
      openScanProductDialog,
      openSubscriptionDialog,
      openTipTermDialog,
      openDownloadAppDialog,
      openContactDialog,
    ]
  )

  return (
    <DialogsContext.Provider value={value}>
      {children}
      <AboutDialog ref={aboutDialog} />
      <CategoryDialog ref={categoryDialog} />
      <FeedDialog ref={feedDialog} />
      <TipDialog ref={tipDialog} />
      <PrivacyPolicyDialog ref={privacyPolicyDialog} />
      <ScanProductDialog ref={scanProductDialog} />
      <SubscriptionDialog ref={subscriptionDialog} />
      <TipTermDialog ref={tipTermDialog} />
      <DownloadAppDialog ref={downloadAppDialog} />
      <ContactDialog ref={contactDialog} />
    </DialogsContext.Provider>
  )
}

export function useDialog() {
  return React.useContext(DialogsContext)
}
