import { Box, Link, Typography, makeStyles } from '@material-ui/core'
import Image from 'next/image'
import React from 'react'

import { useLanguageContext } from 'components/LanguageContext'
import SimpleDialog from '../SimpleDialog'
import LogoAppStore from './assets/logo_app_store.png'
import LogoAppStoreEs from './assets/logo_app_store_es.png'
import LogoPlayStore from './assets/logo_play_store.png'
import LogoPlayStoreEs from './assets/logo_play_store_es.png'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 30,
  },
  title: {
    marginBottom: 20,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('lg')]: {
      whiteSpace: 'normal',
    },
  },
}))

export default React.forwardRef(function DownloadAppDialog({}, ref) {
  const dialogRef = React.useRef()
  const [addonTexts, setAddonTexts] = React.useState([])
  const { i18n, isSpanish } = useLanguageContext()

  const classes = useStyles()

  const open = React.useCallback(addonTexts => {
    setAddonTexts(addonTexts)
    dialogRef.current.open()
  }, [])

  React.useImperativeHandle(ref, () => ({
    open,
  }))

  return (
    <SimpleDialog ref={dialogRef}>
      <Box className={classes.root}>
        {addonTexts.length > 0 &&
          addonTexts.map((addonText, idx) => (
            <Typography
              key={idx}
              variant="h6"
              className={classes.title}
              align="center"
            >
              {addonText}
            </Typography>
          ))}
        <Typography variant="h6" className={classes.title} align="center">
          {i18n.download.title}
        </Typography>
        <Link href={i18n.sharing.shareAppIOSUrl} target="_blank">
          <Image
            src={isSpanish ? LogoAppStoreEs : LogoAppStore}
            width={170}
            height={50}
            alt="app-store-logo"
          />
        </Link>

        <Link href={i18n.sharing.shareAppAdnroidUrl} target="_blank">
          <Image
            src={isSpanish ? LogoPlayStoreEs : LogoPlayStore}
            width={195}
            height={80}
            alt="play-store-logo"
          />
        </Link>
      </Box>
    </SimpleDialog>
  )
})
