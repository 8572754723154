import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
} from '@material-ui/core'
import {
  Close as CloseIcon,
  RouterOutlined as RouterIcon,
  SentimentDissatisfied as ErrorIcon,
} from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import noop from 'lodash/noop'
import React from 'react'

import useSubmitSentryAsync from './useSubmitSentryAsync'
import useValidation from '../useValidation'

const useStyles = makeStyles(theme => ({
  dialog: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    height: '100%',
    width: 70,
    fill: theme.palette.error.light,
  },
  dialogActions: {
    justifyContent: 'center',
  },
  submit: {
    'backgroundColor': theme.palette.error.light,
    'color': theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  reportForm: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

function Subtitle({ noReport }) {
  if (noReport) {
    return <DialogContentText>Our team has been notified.</DialogContentText>
  }

  return (
    <DialogContentText>
      Our team has been notified. If you&apos;d like to help, tell us what
      happened below.
    </DialogContentText>
  )
}

function SubmissionSuccess() {
  return (
    <Alert severity="success">Your feedback has been sent. Thank you!</Alert>
  )
}

function SubmissionError() {
  return (
    <Alert severity="error">
      An unknown error occurred while submitting your report. Please try again.
    </Alert>
  )
}

function Icon({ isNetworkError }) {
  const classes = useStyles()
  if (isNetworkError) {
    return <RouterIcon className={classes.icon} />
  }

  return <ErrorIcon className={classes.icon} />
}

function ActionButton({ color, onClick, canResetError, isTerminalError }) {
  function getText() {
    if (canResetError) {
      return 'TRY AGAIN'
    }

    if (isTerminalError) {
      return 'HOME'
    }

    return 'CLOSE'
  }

  return (
    <Button color={color} onClick={onClick}>
      {getText()}
    </Button>
  )
}

export default React.forwardRef(function UnexpectedErrorDialog(
  { open: initialOpen = false, ...props },
  ref
) {
  const [isOpen, setIsOpen] = React.useState(initialOpen)
  const [values, setValues] = React.useState(props)

  const { type, error, eventId, isTerminalError, canResetError, resetError } =
    values

  const [reportValues, setReportValues] = React.useState({
    name: '',
    email: '',
    comments: '',
  })

  const {
    anyErrors,
    reset: resetValidation,
    validate,
  } = useValidation({
    name: 'required',
    email: ['required', 'email'],
    comments: [
      'required',
      {
        type: 'length',
        minLength: 10,
      },
    ],
  })

  React.useEffect(
    function updateBoundaryProps() {
      // in the case of the dialog being rendered from the error
      // boundary, the reset func is updated through the
      // properties and we must update our copy in the state

      if (canResetError === props.canResetError) {
        // prevent re-render on first run
        return
      }

      setValues(values => ({
        ...values,
        canResetError: props.canResetError,
        resetError: props.resetError,
      }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.canResetError, props.resetError]
  )

  const [submittedReport, setSubmittedReport] = React.useState(false)

  const submitReportAsync = useSubmitSentryAsync()

  const isNetworkError =
    type === 'network' || (error && error.name === 'NetworkError')

  const open = React.useCallback(values => {
    setValues({
      isTerminalError: false,
      canResetError: false,
      resetError: noop,
      ...values,
    })
    setIsOpen(true)
  }, [])

  const close = React.useCallback(
    doNotReset => {
      setIsOpen(false)

      if (canResetError && !doNotReset) {
        resetError()
      }

      // not much we can do at this point, the main page
      // ought to work
      else if (isTerminalError) {
        window.location.href = '/'
      }
    },
    [canResetError, isTerminalError, resetError]
  )

  React.useImperativeHandle(ref, () => ({
    open,
  }))

  const onChange = React.useCallback(
    e => {
      setSubmittedReport(false)
      resetValidation()
      setReportValues(reportValues => ({
        ...reportValues,
        [e.target.name]: e.target.value,
      }))
    },
    [resetValidation]
  )

  const onSubmitReport = React.useCallback(async () => {
    if (!validate(reportValues)) {
      return
    }

    try {
      await submitReportAsync(eventId, reportValues)
      setSubmittedReport(true)
    } catch (error) {
      setSubmittedReport(error)
    }
  }, [reportValues, eventId, submitReportAsync, validate])

  const classes = useStyles()

  const canSubmitReport = !!eventId

  const content = React.useMemo(() => {
    if (submittedReport === true) {
      return (
        <>
          <Subtitle />
          <SubmissionSuccess />
        </>
      )
    }

    if (canSubmitReport) {
      return (
        <form className={classes.reportForm}>
          {submittedReport instanceof Error ? (
            <SubmissionError />
          ) : (
            <Subtitle />
          )}
          <FormControl>
            <TextField
              required
              autoFocus
              name="name"
              label="Name"
              margin="normal"
              value={reportValues.name}
              onChange={onChange}
            />
          </FormControl>
          <FormControl>
            <TextField
              required
              name="email"
              label="E-mail"
              margin="normal"
              value={reportValues.email}
              onChange={onChange}
            />
          </FormControl>
          <FormControl>
            <TextField
              required
              multiline
              rows={3}
              name="comments"
              label="What happened?"
              placeholder="I clicked on 'X' and then hit 'Confirm'"
              margin="normal"
              value={reportValues.comments}
              onChange={onChange}
            />
          </FormControl>
          {anyErrors && (
            <FormHelperText error>Please complete all fields</FormHelperText>
          )}
        </form>
      )
    }

    return <Subtitle noReport />
  }, [
    canSubmitReport,
    reportValues,
    submittedReport,
    anyErrors,
    onChange,
    classes,
  ])

  const actions = React.useMemo(() => {
    if (submittedReport === true) {
      return (
        <Button color="primary" onClick={close}>
          CLOSE
        </Button>
      )
    }

    if (canSubmitReport) {
      return (
        <DialogActions className={classes.dialogActions}>
          <ActionButton
            color="secondary"
            onClick={close}
            isTerminalError={isTerminalError}
            canResetError={canResetError}
          />
          <Button color="primary" variant="contained" onClick={onSubmitReport}>
            SUBMIT
          </Button>
        </DialogActions>
      )
    }

    return (
      <ActionButton
        color="primary"
        onClick={close}
        isTerminalError={isTerminalError}
        canResetError={canResetError}
      />
    )
  }, [
    canSubmitReport,
    onSubmitReport,
    submittedReport,
    canResetError,
    classes,
    close,
    isTerminalError,
  ])

  return (
    <Dialog open={isOpen} maxWidth="xs" className={classes.dialog}>
      <DialogTitle disableTypography>
        <Icon isNetworkError={isNetworkError} />
        <Typography variant="h4">We did not expect this to happen.</Typography>
        {canResetError && (
          <IconButton
            className={classes.closeButton}
            onClick={() => close(true)}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions className={classes.dialogActions}>{actions}</DialogActions>
    </Dialog>
  )
})
