import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import clsx from 'clsx'
import Quagga from 'quagga'
import React from 'react'

const useStyles = makeStyles(theme => ({
  scanArea: {
    'position': 'relative',
    'display': 'flex',
    'justifyContent': 'center',
    'width': '100%',
    'height': '95%',
    'marginTop': 15,
    '& > .drawingBuffer': {
      display: 'none',
    },
  },
  loader: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#39393980',
    width: '100%',
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
}))

export default React.forwardRef(function BarcodeScanner(
  { hidden, onDetected, onInitialize, onError },
  ref
) {
  const [loading, setLoading] = React.useState(false)

  const onInitializeFinish = React.useCallback(
    error => {
      try {
        if (error) {
          Sentry.addBreadcrumb({
            category: 'BarcodeScanner',
            message: 'Failed to init scanner',
            data: {
              error,
            },
          })

          if (onError) {
            onError(error)
          }
          return
        }

        if (onInitialize) {
          onInitialize()
        }

        Quagga.start()
      } finally {
        setLoading(false)
      }
    },
    [onError, onInitialize]
  )

  const initScanner = React.useCallback(() => {
    setLoading(true)

    Quagga.init(
      {
        inputStream: {
          target: document.querySelector('#scanArea'),
        },
        frequency: 1,
        decoder: {
          readers: [
            'code_128_reader',
            'ean_reader',
            'ean_8_reader',
            'code_39_reader',
            'code_39_vin_reader',
            'codabar_reader',
            'upc_reader',
            'upc_e_reader',
            'i2of5_reader',
            '2of5_reader',
            'code_93_reader',
          ],
        },
      },
      onInitializeFinish
    )

    Quagga.onDetected(onDetected)
  }, [onDetected, onInitializeFinish])

  React.useEffect(
    function init() {
      initScanner()

      return Quagga.stop
    },
    [initScanner]
  )

  const startScanner = React.useCallback(() => {
    const video = document.getElementById('scanArea')
    video.getElementsByTagName('video')[0].play()
    initScanner()
  }, [initScanner])

  const pauseScanner = React.useCallback(() => {
    const video = document.getElementById('scanArea')
    video.getElementsByTagName('video')[0].pause()
    Quagga.stop()
  }, [])

  React.useImperativeHandle(ref, () => ({
    start: startScanner,
    pause: pauseScanner,
  }))

  React.useEffect(() => {
    const scannerScreen = document.getElementsByTagName('video')[0]
    if (scannerScreen) {
      scannerScreen.style.width = '100%'
    }
  }, [onInitialize])

  const classes = useStyles()

  return (
    <>
      <Box
        id="scanArea"
        className={clsx({
          [classes.scanArea]: true,
          [classes.hidden]: hidden,
        })}
      >
        {loading && (
          <Box className={classes.loader}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  )
})
