import React from 'react'

import ScanProduct from '../ScanProduct'
import SimpleDialog from '../SimpleDialog'

export default React.forwardRef(function ScanProductDialog({}, ref) {
  const dialogRef = React.useRef()

  const open = React.useCallback(() => {
    dialogRef.current.open()
  }, [])

  React.useImperativeHandle(ref, () => ({
    open,
  }))

  return (
    <SimpleDialog ref={dialogRef}>
      <ScanProduct />
    </SimpleDialog>
  )
})
