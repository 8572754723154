import { createTheme } from '@material-ui/core/styles'

const defaultTheme = createTheme()

const theme = createTheme({
  palette: {
    primary: {
      main: '#2962FF',
    },
    text: {
      primary: '#3A405A',
      secondary: '#14307F',
      navBar: '#83878c',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontSize: 36,
      fontWeight: 'bold',
      [defaultTheme.breakpoints.only('xs')]: {
        fontSize: 29,
      },
    },
    h2: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 20,
    },
    h5: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 16,
      fontWeight: 'normal',
    },
    subtitle2: {
      fontSize: 16,
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: 13,
      },
      [defaultTheme.breakpoints.down(400)]: {
        fontSize: 11,
      },
    },
    caption: {
      fontSize: 14,
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      [defaultTheme.breakpoints.down(400)]: {
        fontSize: 10,
      },
    },
  },
})

export default theme
