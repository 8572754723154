import { Box, Link, Typography, makeStyles } from '@material-ui/core'
import React from 'react'

import { useLanguageContext } from 'components/LanguageContext'
import SimpleDialog from '../SimpleDialog'

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    padding: 20,
  },
})

export default React.forwardRef(function ContactDialog({}, ref) {
  const dialogRef = React.useRef()
  const { i18n } = useLanguageContext()

  const classes = useStyles()

  const open = React.useCallback(() => {
    dialogRef.current.open()
  }, [])

  React.useImperativeHandle(ref, () => ({
    open,
  }))

  return (
    <SimpleDialog ref={dialogRef} height="auto">
      <Box className={classes.root}>
        <Typography variant="h4">
          {i18n.contactDialog.title}{' '}
          <Link href={`mailto:${i18n.contactDialog.contactEmail}`}>
            {i18n.contactDialog.contactEmail}
          </Link>
        </Typography>
      </Box>
    </SimpleDialog>
  )
})
