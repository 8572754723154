import { Box, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { useQuery } from 'react-query'

import Api from 'api'
import { useLanguageContext } from 'components/LanguageContext'
import parseHtml from 'components/parseHtml'
import SimpleDialog from '../SimpleDialog'

const useStyles = makeStyles(theme => ({
  introContainer: {
    marginTop: 15,
  },
  contentRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
}))

export default React.forwardRef(function CategoryDialog({}, ref) {
  const { isSpanish, language } = useLanguageContext()
  const dialogRef = React.useRef()

  const [category, setCategory] = React.useState(null)

  const { data: pagesData } = useQuery({
    queryKey: ['pages', category?.guide],
    queryFn: () => Api.getPages({ slug: category?.guide }),
    enabled: !!category,
  })

  const { data: categoriesData } = useQuery({
    queryKey: ['categories'],
    queryFn: Api.getCategories,
  })

  const open = React.useCallback(category => {
    if (!category) {
      return
    }

    setCategory(category)

    dialogRef.current.open()
  }, [])

  React.useImperativeHandle(ref, () => ({
    open,
  }))

  const classes = useStyles()

  const categoryInfo = categoriesData?.categories.find(
    x => x.id === category?.id
  )
  const guides = pagesData?.pages

  const renderContent = React.useCallback(() => {
    if (!categoryInfo) {
      return
    }

    const guide = guides?.find(x => x.language === language)

    return (
      <Box className={classes.contentRoot}>
        <Typography variant="h2" color="textPrimary">
          {isSpanish ? categoryInfo.name_es : categoryInfo.name}
        </Typography>
        <Box className={classes.introContainer}>
          <Typography variant="subtitle2">
            {isSpanish
              ? categoryInfo.introduction_es
              : categoryInfo.introduction}
          </Typography>
        </Box>
        {guide ? parseHtml(guide.content) : null}
      </Box>
    )
  }, [categoryInfo, language, classes, isSpanish, guides])

  return <SimpleDialog ref={dialogRef}>{renderContent()}</SimpleDialog>
})
