import { useRouter } from 'next/router'
import React from 'react'

import locale from '../locale'

export const LanguageContext = React.createContext()

export default function LanguageContextProvider({ children }) {
  const router = useRouter()
  const [language, setLanguage] = React.useState(router.locale ?? 'en')

  const toggleLanguage = React.useCallback(() => {
    const lang = language === 'es' ? 'en' : 'es'

    setLanguage(lang)

    router.push('/', undefined, {
      locale: lang,
      shallow: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  const isSpanish = language === 'es'

  const value = React.useMemo(
    () => ({
      i18n: isSpanish ? locale.es : locale.en,
      language: language,
      toggleLanguage,
      isSpanish,
    }),
    [language, isSpanish, toggleLanguage]
  )

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

export function useLanguageContext() {
  return React.useContext(LanguageContext)
}
