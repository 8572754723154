import { Box, SvgIcon, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles(theme => ({
  shareButton: {
    'border': '1px solid white',
    'color': 'white',
    'borderRadius': 20,
    'cursor': 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  shareContainer: {
    'position': 'relative',
    'zIndex': 1,
    '& > .shareActionsContainer': {
      position: 'absolute',
      display: 'none',
      width: 50,
      paddingTop: ({ top }) => top,
      left: ({ left }) => left,
    },
    '& .shareActionsInnerContainer': {
      padding: '10px 0',
      height: 135,
      borderRadius: 25,
      boxShadow: '0 0 4px rgba(0, 0, 0, .25)',
      backgroundColor: 'white',
      textAlign: 'center',
    },
    '& .shareActionsInnerContainer img': {
      marginTop: 5,
      cursor: 'pointer',
    },
    '&:hover > .shareActionsContainer': {
      display: 'block',
    },
    [theme.breakpoints.down('373')]: {
      margin: '5px auto 0px 0px',
    },
  },
  faceBookButton: {
    'color': theme.palette.text.navBar,
    'borderColor': theme.palette.text.navBar,
    '&:hover': {
      color: 'white',
      borderColor: 'white',
      backgroundColor: 'rgb(25, 74, 135)',
    },
  },
  linkedInButton: {
    'color': theme.palette.text.navBar,
    'borderColor': theme.palette.text.navBar,
    '&:hover': {
      color: 'white',
      borderColor: 'white',
      backgroundColor: 'rgb(0, 103, 164)',
    },
  },
  twitterButton: {
    'color': theme.palette.text.navBar,
    'borderColor': theme.palette.text.navBar,
    '&:hover': {
      color: 'white',
      borderColor: 'white',
      backgroundColor: 'rgb(0, 145, 228)',
    },
  },
  button: {
    border: 'none',
    background: 'none',
  },
}))

function ShareButton(props) {
  const onShare = React.useCallback(
    e => {
      e.preventDefault()
      const width = 580
      const height = 425
      const topPos =
        window.top.outerHeight / 2 + window.top.screenY - height / 2
      const leftPos = window.top.outerWidth / 2 + window.top.screenX - width / 2

      window.open(
        props.url,
        'Share',
        `width=${width},height=${height},top=${topPos},left=${leftPos}`
      )
    },
    [props.url]
  )

  const classes = useStyles()
  return (
    <button onClick={onShare} className={classes.button}>
      <SvgIcon
        viewBox="0 0 30 30"
        fontSize="large"
        className={clsx(
          classes.shareButton,
          props.classes && props.classes.root
        )}
      >
        <path d={props.path} />
      </SvgIcon>
    </button>
  )
}

function FacebookButton({ shareText, shareUrl, ...props }) {
  const text = shareText.replace('#handle#', process.env.NEXT_PUBLIC_FB_HANDLE)
  const url = [
    'https://www.facebook.com/sharer.php',
    '?display=popup',
    `&u=${encodeURIComponent(process.env.NEXT_PUBLIC_BASE_URL)}/${
      shareUrl || ''
    }`,
    `&quote=${encodeURIComponent(text)}`,
  ].join('')

  return (
    <ShareButton
      {...props}
      url={url}
      path="M15.9904 12.12V10.9026C15.9904 10.3188 16.3771 10.179 16.657 10.179H18.3595V7.57947H16.0153C13.4077 7.57947 12.8236 9.50427 12.8236 10.7547V12.12H11.3101V13.9542V15.1554H12.8401V22.7394H15.8755V15.1554H18.121L18.2197 13.9626L18.4006 12.12H15.9904Z"
    />
  )
}

function LinkedInButton({ shareText, shareUrl, ...props }) {
  const text = shareText.replace(
    '#handle#',
    process.env.NEXT_PUBLIC_LINKEDIN_HANDLE
  )
  const url = [
    'https://www.linkedin.com/sharing/share-offsite/',
    `?url=${encodeURIComponent(process.env.NEXT_PUBLIC_BASE_URL)}/${
      shareUrl || ''
    }`,
    `&summary=${text}`,
  ].join('')

  return (
    <ShareButton
      {...props}
      url={url}
      path="M9.71461 8.45139C8.85091 8.45139 8.15161 9.15069 8.15161 10.0144C8.15161 10.8781 8.85091 11.5771 9.71461 11.5771C10.5783 11.5771 11.2773 10.8781 11.2773 10.0144C11.2773 9.15069 10.5783 8.45139 9.71461 8.45139ZM21.4854 15.6652C21.2796 13.9708 20.5068 12.9181 18.2199 12.9181C16.8789 12.9181 15.9741 13.4116 15.5958 14.1271H15.5547V12.9181H13.062V14.6371V21.859H15.6699V17.4259C15.6699 16.2574 15.9 15.1309 17.3319 15.1309C18.7707 15.1309 18.8775 16.4632 18.8775 17.5078V21.859H21.5592V16.9486C21.5592 16.4878 21.5343 16.0603 21.4854 15.6652ZM8.15161 15.295V21.859H11.2773V14.7604V12.9181H8.15161V15.295Z"
    />
  )
}

function TwitterButton({ shareText, shareUrl, ...props }) {
  const text = shareText.replace(
    '#handle#',
    process.env.NEXT_PUBLIC_TWITTER_HANDLE
  )
  const url = [
    'https://twitter.com/intent/tweet',
    `?text=${encodeURIComponent(text)}`,
    `&url=${encodeURIComponent(process.env.NEXT_PUBLIC_BASE_URL)}/${
      shareUrl || ''
    }`,
  ].join('')

  return (
    <ShareButton
      {...props}
      url={url}
      path="M22.4394 10.5324C21.888 10.7712 21.288 10.9356 20.6547 11.0178C21.3042 10.6314 21.7974 10.0389 22.0284 9.31497C21.4278 9.66867 20.7534 9.92367 20.046 10.0635C19.4859 9.46347 18.6723 9.09297 17.7675 9.09297C16.0566 9.09297 14.658 10.4667 14.658 12.1527C14.658 12.3996 14.6994 12.63 14.7486 12.8523C12.1578 12.7287 9.86253 11.5113 8.33253 9.65217C8.06943 10.113 7.90503 10.6314 7.90503 11.1906C7.90503 12.2598 8.46423 13.1976 9.28653 13.7406C8.77683 13.7325 8.29953 13.5924 7.88043 13.362V13.4031C7.88043 13.7979 7.95453 14.16 8.09403 14.5053C8.47263 15.468 9.33633 16.1916 10.3812 16.3974C10.1091 16.4715 9.84603 16.5123 9.55833 16.5123C9.36093 16.5123 9.16353 16.4877 8.97393 16.4466C9.37743 17.664 10.5207 18.5523 11.8782 18.5853C10.8084 19.4079 9.47613 19.8933 8.02023 19.8933C7.76493 19.8933 7.52673 19.8765 7.27173 19.8519C8.65353 20.7159 10.2903 21.2259 12.0426 21.2259C16.9038 21.2259 19.9062 17.8614 20.6871 14.3655C20.8275 13.7406 20.8929 13.1235 20.8929 12.5148V12.12C21.4935 11.6841 22.0284 11.1411 22.4394 10.5324Z"
    />
  )
}

export default function ShareSocial({
  top,
  left,
  shareText,
  shareUrl,
  children,
}) {
  const classes = useStyles({ top, left })

  return (
    <Box className={classes.shareContainer}>
      {children}
      <Box className="shareActionsContainer">
        <Box className="shareActionsInnerContainer">
          <FacebookButton
            shareText={shareText}
            shareUrl={shareUrl}
            classes={{ root: classes.faceBookButton }}
          />
          <LinkedInButton
            shareText={shareText}
            shareUrl={shareUrl}
            classes={{ root: classes.linkedInButton }}
          />
          <TwitterButton
            shareText={shareText}
            shareUrl={shareUrl}
            classes={{ root: classes.twitterButton }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export { FacebookButton, LinkedInButton, TwitterButton }
