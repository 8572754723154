import React from 'react'

export const TipFilterContext = React.createContext()

export default function TipFilterContextProvider({ children }) {
  const [selectedCategory, setSelectedCategory] = React.useState(null)
  const [searchText, setSearchText] = React.useState('')

  const clearCategorySelection = React.useCallback(() => {
    setSelectedCategory(null)
    setSearchText('')
  }, [])

  const onCategoryChange = React.useCallback(category => {
    setSelectedCategory(category)
    setSearchText('')
  }, [])

  const value = React.useMemo(
    () => ({
      selectedCategory,
      searchText,
      setSearchText,
      setSelectedCategory,
      clearCategorySelection,
      onCategoryChange,
    }),
    [
      selectedCategory,
      searchText,
      setSearchText,
      setSelectedCategory,
      clearCategorySelection,
      onCategoryChange,
    ]
  )

  return (
    <TipFilterContext.Provider value={value}>
      {children}
    </TipFilterContext.Provider>
  )
}

export function useTipFilter() {
  return React.useContext(TipFilterContext)
}
