import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { useQuery } from 'react-query'

import Api from 'api'
import { useLanguageContext } from 'components/LanguageContext'
import parseHtml from 'components/parseHtml'
import SimpleDialog from './SimpleDialog'

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default React.forwardRef(function TipTermDialog({}, ref) {
  const { i18n, language } = useLanguageContext()
  const dialogRef = React.useRef()
  const [term, setTerm] = React.useState(null)

  const { isLoading, data } = useQuery({
    queryKey: ['pages', term],
    queryFn: () => Api.getPages({ slug: term }),
    enabled: !!term,
  })

  const open = React.useCallback(term => {
    setTerm(term)
    dialogRef.current.open()
  }, [])

  React.useImperativeHandle(ref, () => ({
    open,
  }))

  const classes = useStyles()

  const renderData = React.useCallback(() => {
    if (isLoading) {
      return (
        <Box className={classes.container}>
          <CircularProgress />
        </Box>
      )
    }

    const termData = data?.pages.find(x => x.language === language)

    if (!termData) {
      return (
        <Box>
          <Typography variant="h6">{i18n.tipTerm.noContent}</Typography>
        </Box>
      )
    }

    return (
      <Box>
        <Typography variant="h2" color="textPrimary" gutterBottom>
          {termData.title}
        </Typography>
        <Typography variant="h6">{parseHtml(termData.content)}</Typography>
      </Box>
    )
  }, [isLoading, data, classes, i18n, language])

  return <SimpleDialog ref={dialogRef}>{renderData()}</SimpleDialog>
})
