import React from 'react'

import SimpleDialog from '../SimpleDialog'

export default React.forwardRef(function SubscriptionDialog({}, ref) {
  const dialogRef = React.useRef()

  const open = React.useCallback(() => {
    dialogRef.current.open()
  }, [])

  React.useImperativeHandle(ref, () => ({
    open,
  }))

  React.useEffect(() => {
    if (!document.getElementById('signupScript')) {
      const variableScript = document.createElement('script')
      variableScript.text = 'var _ctct_m = "95e1e84b43eb342f326c1b34aa74520a"'
      document.getElementsByTagName('head')[0].appendChild(variableScript)

      const formScript = document.createElement('script')
      formScript.async = true
      formScript.defer = true
      formScript.src =
        '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js'
      formScript.id = 'signupScript'
      document.getElementsByTagName('head')[0].appendChild(formScript)
    }
  }, [])

  return (
    <SimpleDialog ref={dialogRef} keepMounted>
      <div
        className="ctct-inline-form"
        data-form-id="dacf9bb1-b0eb-4af6-8eac-338b6e14a2f3"
      ></div>
    </SimpleDialog>
  )
})
