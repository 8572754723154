const badgeImages = {
  badge_authority: require('./assets/badge_authority.png'),
  badge_brainiac: require('./assets/badge_brainiac.png'),
  badge_change_maker: require('./assets/badge_change_maker.png'),
  badge_clean_air: require('./assets/badge_clean_air.png'),
  badge_clean_body: require('./assets/badge_clean_body.png'),
  badge_clean_house: require('./assets/badge_clean_house.png'),
  badge_clean_sweep: require('./assets/badge_clean_sweep.png'),
  badge_climber: require('./assets/badge_climber.png'),
  badge_diy_master: require('./assets/badge_diy_master.png'),
  badge_happy_kid: require('./assets/badge_happy_kid.png'),
  badge_healthy_food: require('./assets/badge_healthy_food.png'),
  badge_healthy_home: require('./assets/badge_healthy_home.png'),
  badge_healthy_industry: require('./assets/badge_healthy_industry.png'),
  badge_healthy_street: require('./assets/badge_healthy_street.png'),
  badge_low_chem_clothing: require('./assets/badge_low_chem_clothing.png'),
  badge_low_chem_living: require('./assets/badge_low_chem_living.png'),
  badge_right_path: require('./assets/badge_right_path.png'),
  badge_scholar: require('./assets/badge_scholar.png'),
  badge_teacher: require('./assets/badge_teacher_coach.png'),
}

const badges = [
  {
    key: 'badge_brainiac',
    color: '#ff5252',
    name: 'Brainiac',
    name_es: 'Cerebrito',
    teaser: 'View any ten tips, or choose from among the unread tips below.',
    teaser_es:
      'Ver diez consejos, o elegir entre los consejos no leídos abajo.',
    completion: {
      category: null,
    },
  },
  {
    key: 'badge_right_path',
    color: '#f50057',
    name: 'On the Right Path',
    name_es: 'Por buen camino',
    teaser: 'Mark "I want to work on this" or "I do this" for any five tips.',
    teaser_es: 'Marcar cinco consejos indicando "En proceso" u "OK".',
    completion: {
      category: null,
    },
  },
  {
    key: 'badge_change_maker',
    color: '#8e24aa',
    name: 'Change Maker',
    name_es: 'Efectuando cambios',
    teaser: 'Mark "I do this" for any five tips.',
    teaser_es:
      'Marcar cinco consejos indicando "OK", o elegir entre los consejos abajo.\t',
    completion: {
      category: null,
    },
  },
  {
    key: 'badge_diy_master',
    color: '#8e24aa',
    name: 'DIY Master',
    name_es: 'Maestría en DIY',
    teaser:
      'Mark "I do this" on any three tips for making a DIY alternative to a commercial product.',
    teaser_es:
      'Marcar "OK" en tres consejos sobre recetas caseras que pueden reemplazar productos comerciales.\t\t',
    completion: {
      category: 'diy',
    },
  },
  {
    key: 'badge_healthy_industry',
    color: '#2962ff',
    name: 'Clean Shop',
    name_es: 'Tienda limpia',
    teaser:
      'Mark "I do this" on any three tips for choosing less toxic products.',
    teaser_es:
      'Marcar "OK" en tres consejos sobre la elección de productos menos tóxicos.\t',
    completion: {
      category: 'buy',
    },
  },
  {
    key: 'badge_healthy_street',
    color: '#f7931d',
    name: 'Healthy Street',
    name_es: 'Calle sana',
    teaser:
      'Mark "I do this" on any three tips for taking action in your neighborhood, school, work, or government.',
    teaser_es:
      'Marcar "OK" en tres consejos sobre la importancia de actuar en tu barrio, escuela, lugar de trabajo o gobierno.',
    completion: {
      category: 'activism',
    },
  },
  {
    key: 'badge_clean_air',
    color: '#ff5252',
    name: 'Clean Air',
    name_es: 'Aire limpio',
    teaser:
      'Mark "I do this" on any three tips for reducing toxic chemicals in indoor air and dust.',
    teaser_es:
      'Marcar "OK" en tres consejos para reducir los productos químicos tóxicos en el aire de espacios interiores y el polvo del hogar.\t\t',
    completion: {
      category: 'air',
    },
  },
  {
    key: 'badge_low_chem_living',
    color: '#ff5252',
    name: 'Low-Chem Living',
    name_es: 'Una vida menos tóxica',
    teaser:
      'Mark "I do this" on any three tips for reducing your use of household and personal care products.',
    teaser_es:
      'Marcar "OK" en tres consejos para reducir del uso de productos domésticos y productos de cuidado personal.\t\t',
    completion: {
      category: 'notNeeded',
    },
  },
  {
    key: 'badge_clean_body',
    color: '#16af89',
    name: 'Clean Body',
    name_es: 'Cuerpo limpio',
    teaser: 'Mark "I do this" on any ten tips in the Personal Care section.',
    teaser_es:
      'Marcar "OK" en diez consejos para reducir sustancias tóxicas de los productos de cuidado personal.\t\t',
    completion: {
      category: 646,
    },
  },
  {
    key: 'badge_healthy_home',
    color: '#f50057',
    name: 'Healthy Home',
    name_es: 'Hogar sano',
    teaser: 'Mark "I do this" on ten tips in the Home section.',
    teaser_es:
      'Marcar "OK" en diez consejos para reducir tu exposición en tu hogar y fuera de él.\t',
    completion: {
      category: 647,
    },
  },
  {
    key: 'badge_low_chem_clothing',
    color: '#8e24aa',
    name: 'Low-Chem Clothing',
    name_es: 'Ropa natural',
    teaser: 'Mark "I do this" on ten tips in the Clothing section.',
    teaser_es:
      'Marcar "OK" en diez consejos sobre la reducción de sustancias tóxicas de la ropa y su lavado.\t',
    completion: {
      category: 651,
    },
  },
  {
    key: 'badge_happy_kid',
    color: '#8e24aa',
    name: 'Happy Kid',
    name_es: 'Niño feliz',
    teaser: 'Mark "I do this" on ten tips in the Children section.',
    teaser_es:
      'Marcar "OK" en diez consejos para reducir la exposición de los niños a sustancias tóxicas.\t',
    completion: {
      category: 650,
    },
  },
  {
    key: 'badge_clean_house',
    color: '#a1bd41',
    name: 'Clean House',
    name_es: 'Casa limpia',
    teaser: 'Mark "I do this" on ten tips in the Cleaning section.',
    teaser_es:
      'Marcar "OK" en diez consejos para reducir las sustancias tóxicas en los productos de limpieza.\t',
    completion: {
      category: 648,
    },
  },
  {
    key: 'badge_healthy_food',
    color: '#2962ff',
    name: 'Healthy Food',
    name_es: 'Comida sana',
    teaser: 'Mark "I do this" on ten tips in the Food and Drink section.',
    teaser_es:
      'Marcar "OK" en diez consejos para reducir la exposición a productos químicos tóxicos en las comidas y las bebidas.\t\t',
    completion: {
      category: 649,
    },
  },
  {
    key: 'badge_scholar',
    color: '#2962ff',
    name: 'Scholar',
    name_es: 'Autoridad académica',
    teaser: 'View any thirty tips, or choose from among the unread tips below.',
    teaser_es:
      'Ver treinta consejos, o elegir entre los consejos no leídos abajo.',
    completion: {
      category: null,
    },
  },
  {
    key: 'badge_teacher',
    color: '#16af89',
    name: 'Coach',
    name_es: 'Cuerpo técnico',
    teaser:
      'View any one hundred tips, or choose from among the unread tips below.',
    teaser_es:
      'Ver cien consejos, o elegir entre los consejos no leídos abajo.\t',
    completion: {
      category: null,
    },
  },
  {
    key: 'badge_climber',
    color: '#f7931d',
    name: 'Climber',
    name_es: 'Escalando alto',
    teaser: 'Mark "I want to work on this" or "I do this" for any thirty tips.',
    teaser_es: 'Marcar "En proceso" u "OK" en treinta consejos.\t',
    completion: {
      category: null,
    },
  },
  {
    key: 'badge_authority',
    color: '#ff5252',
    name: 'Slam Dunk',
    name_es: 'Agente de cambio',
    teaser: 'View all tips, or choose from among the unread tips below.',
    teaser_es:
      'Ver todos los consejos, o elegir entre los consejos no leídos abajo.',
    completion: {
      category: null,
    },
  },
  {
    key: 'badge_clean_sweep',
    color: '#8e24aa',
    name: 'Pure Life',
    name_es: 'Vida pura',
    teaser:
      'Mark "I want to work on this" or "I do this" for 95% of all tips, or choose from undone tips below.',
    teaser_es:
      'Marcar "En proceso" u "OK" en el 95% de los consejos, o elegir entre los consejos no marcados abajo.\t\t',
    completion: {
      category: null,
    },
  },
]

export { badges, badgeImages }
