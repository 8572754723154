const en = {
    bySilentSpring: 'By Silent Spring Institute',
    categories: 'Categories',
    category: {
        top10guide: 'Top 10 Tips and Buying Guide'
    },
    contactDialog: {
        title: 'For inquiries, please send us an email at',
        contactEmail: 'detoxme@silentspring.org'
    },
    dialog: {
        close: 'Close'
    },
    download: {
        title:
            'Download Detox Me mobile app to collect badges and track your progress!'
    },
    footer: {
        contact: 'Contact',
        subscripion: 'Keep up to date',
        siteUrl: 'https://silentspring.org/'
    },
    help: {
        title: 'How to use Detox Me',
        identify:
            'Identify sources of toxic chemicals in your home and learn how to reduce your exposures.',
        share:
            'Share our quick and easy action-oriented tips with friends and family.',
        donate:
            'Support our work. Help us advance science on the links between environmental chemicals and health.',
        scan: "Scan a product's barcode to get relevant tips.",
        selectCategory:
            'Browse tips by category. Click to select, click again to deselect.',
        tipSearch: 'Search for tips using different keywords.',
        categoryInfo:
            'See our top tips and use our Buying Guide to decode product labels and find non-toxic alternatives.',
        tipSelect: 'Click on a tip to learn more.',
        newsContainer: 'Get the latest news on toxics.',
        feedSelect: 'Click on a headline and find relevant tips.',
        feedToggle: 'Click on the toggle to get only news with tips',
        feedReadMore: 'Read the original article.',
        previous: 'Previous',
        next: 'Next',
        done: 'Done'
    },
    home: {
        title: 'Detox Me app: Tips for healthier living',
        description: 'Silent Spring’s Detox Me app is a clean lifestyle guide that walks you through simple, research-based tips on how to reduce your exposure to harmful chemicals where you live, work, and play.'
    },
    introduction: {
        title: 'Welcome!',
        subtitle:
            'Silent Spring’s Detox Me app helps you make choices to reduce your exposure to harmful chemicals where you live, work, and play.',
        text: ''
    },
    menu: {
        about: 'About Detox Me',
        help: 'Help',
        donate: 'Donate',
        donateUrl:
            'https://secure.lglforms.com/form_engine/s/mhIk3MeSR5Yels3WxBGW5w',
        privacy: 'Privacy Policy',
        share: 'Share Detox Me',
        scanProductBtn: 'Scan Product'
    },
    newsFeed: {
        title: 'News Feed',
        readMore: 'Read more',
        relatedTips: 'Related Tips',
        showNewsWithTips: 'Show only news items with tips'
    },
    privacyPolicy: {
        content:
            'Silent Spring Institute will collect anonymous data on users’ profiles, their preferences, and how they use the app. This data will not be linked to you and will never be used for ads or sold for marketing purposes. This information will be used solely for the purposes of improving the app and directing our research on environmental chemicals and health.'
    },
    search: {
        title: 'Search Tips',
        getTips: 'Get tips',
        getTipsFor: 'Get tips for ',
        noTipsFound: 'No tips matched your search term.'
    },
    scanner: {
        scanHelpText: `If the camera is not detecting the barcode, make sure there is plenty of light but no reflection on the label and the barcode is in focus.`,
        scanAgainBtn: 'Scan again',
        permissionCamera: `Please allow access to your camera, when prompted, in order
            to scan your product's barcode.`,
        errorPermissionCamera: 'There was a problem accessing your camera: ',
        noProductsFound: 'No product found for ',
        relatedTips: 'Related tips found for ',
        noSpecificTips:
            'Detox Me does not provide information on specific brands. Instead, it provides detailed purchasing criteria to help you make more informed decisions and live a healthier life.'
    },
    sharing: {
        shareAppMessage: 'Check out Detox Me App! web.detoxmeapp.org',
        shareAppIOSUrl: 'https://apps.apple.com/us/app/detox-me/id1056195091',
        shareAppAdnroidUrl:
            'https://play.google.com/store/apps/details?id=org.silentspring.myapplication&hl=en&gl=US'
    },
    subscriptionDialog: {
        title: 'Subscribe to keep up to date',
        email: 'Email address',
        firstName: 'First Name',
        lastName: 'Last Name',
        country: 'Select Country',
        subscribe: 'Subscribe'
    },
    tip: {
        why: 'Why?',
        shareMsg: 'Check out this tip I found in #handle#.',
        downloadApp:
            'Download Detox Me mobile app to collect badges and track your progress!',
        clearSelection: 'Clear selection'
    },
    tipTerm: {
        noContent: 'No tips matched your search term.'
    },
    validation: {
        invalidEmail: '*Invalid email',
        requiredField: '*Field is required'
    }
}

const es = {
    bySilentSpring: 'Por Silent Spring Institute',
    categories: 'Categorias',
    category: {
        top10guide: 'Los 10 mejores consejos y guía de compra'
    },
    contactDialog: {
        title:
            'Si desea hacer preguntas, envíenos un mensaje de correo electrónico a',
        contactEmail: 'detoxme@silentspring.org'
    },
    dialog: {
        close: 'Cerca'
    },
    download: {
        title:
            'Descargue la aplicación móvil Detox Me para coleccionar insignias y seguir su progreso.'
    },
    footer: {
        contact: 'Contacto',
        subscripion: 'Mantenerse al día',
        siteUrl: 'https://silentspring.org/'
    },
    help: {
        title: 'Cómo usar Detox Me',
        identify:
            'Identifique las fuentes de sustancias químicas tóxicas en su hogar y aprenda a reducir su exposición.',
        share:
            'Comparta con sus amigos y familiares nuestros rápidos y sencillos consejos para reducir la exposición.',
        donate:
            'Apoye nuestra tarea. Ayúdenos a lograr el avance de la ciencia sobre los vínculos que hay entre las sustancias químicas en el medio ambiente y la salud.',
        scan:
            'Escanee el código de barras de un producto para obtener consejos relevantes.',
        selectCategory:
            'Busque consejos por categorías. Haga clic para seleccionar y vuelva a hacer clic para cancelar la selección.',
        tipSearch: 'Busque consejos utilizando diferentes palabras clave.',
        categoryInfo:
            'Consulte nuestros principales consejos y utilice nuestra Guía de compra para entender las etiquetas de los productos y encontrar alternativas que no sean tóxicas.',
        tipSelect: 'Haga clic en un consejo para obtener más información.',
        newsContainer: 'Reciba las últimas noticias sobre productos tóxicos.',
        feedSelect:
            'Haga clic en un encabezado y encuentre consejos pertinentes. ',
        feedToggle: 'Click on the toggle to get only news with tips',
        feedReadMore: 'Lea el artículo original.',
        previous: 'Previo',
        next: 'Próximo',
        done: 'Terminado'
    },
    home: {
        title: 'Detox Me app: Consejos para una vida más saludable',
        description: 'Detox Me, una aplicación creada por científicos apasionados de Silent Spring Institute, ofrece simples consejos basados en la investigación para reducir el contacto con estos productos químicos tóxicos.'
    },
    introduction: {
        title: '¡Bienvenido!',
        subtitle:
            'La aplicación Detox Me de Silent Spring le ayuda a tomar decisiones para reducir su exposición a sustancias químicas nocivas presentes en los lugares donde vive, trabaja y juega.',
        text: ''
    },
    menu: {
        about: 'Acerca de Detox Me',
        help: 'Ayuda',
        donate: 'Donar',
        donateUrl:
            'https://secure.lglforms.com/form_engine/s/mhIk3MeSR5Yels3WxBGW5w',
        privacy: 'Política de privacidad',
        share: 'Compartir Detox Me',
        scanProductBtn: 'Escanear un producto'
    },
    newsFeed: {
        title: 'Noticias',
        readMore: 'Lea Más',
        relatedTips: 'Consejos relacionados',
        showNewsWithTips: 'Mostrar solo nuevas noticias con sugerencias'
    },
    privacyPolicy: {
        content:
            'Silent Spring Institute recoge datos anónimos sobre los perfiles de los usuarios, sus preferencias y la forma en la que utilizan la aplicación. Los datos no se vincularán con los usuarios y no se utilizarán para anuncios ni se venderán con fines de mercadeo. Esta información se utilizará únicamente para mejorar la aplicación y dirigir nuestra investigación sobre productos químicos ambientales y de la salud.'
    },
    scanner: {
        scanAgainBtn: 'Escanea otra vez',
        permissionCamera: `Para poder escanear el código de barras de un producto debe permitir el acceso a su cámara cuando se le solicite.`,
        errorPermissionCamera:
            'Se detectó un problema al intentar tener acceso a su cámara.',
        noProductsFound: 'No se ha encontrado ningún producto para ',
        relatedTips: 'Consejos relacionados encontrados para ',
        noSpecificTips:
            'Detox Me no provee información de marcas específicas. En su lugar, provee un criterio de compra con detalles para ayudarle a tomar decisiones con conocimiento y vivir una vida más sana.'
    },
    search: {
        title: 'Buscar Consejos',
        getTips: 'Obtener consejos',
        getTipsFor: 'Obtener consejos para ',
        noTipsFound:
            'No tenemos consejos que coincidan con los términos de su búsqueda.'
    },
    sharing: {
        shareAppMessage: 'Check out Detox Me App! web.detoxmeapp.org',
        shareAppIOSUrl: 'https://apps.apple.com/us/app/detox-me/id1056195091',
        shareAppAdnroidUrl:
            'https://play.google.com/store/apps/details?id=org.silentspring.myapplication&hl=en&gl=US'
    },
    subscriptionDialog: {
        title: 'Suscríbete para estar al día',
        email: 'Email',
        firstName: 'Primer nombre',
        lastName: 'Apellido',
        country: 'Seleccionar país',
        subscribe: 'Suscribir'
    },
    tip: {
        why: '¿Por qué?',
        shareMsg: 'Fíjense en este consejo que encontré en #handle#.',
        downloadApp:
            'Descargue la aplicación móvil Detox Me para coleccionar insignias y seguir su progreso.',
        clearSelection: 'Eliminar selección'
    },
    tipTerm: {
        noContent: 'No se encontró información para este término.'
    },
    validation: {
        invalidEmail: '*Correo electrónico inválido',
        requiredField: '*Campo obligatorio'
    }
}

export default { en, es }
