import Alert from '@material-ui/lab/Alert'
import { Box, SvgIcon, Typography, makeStyles } from '@material-ui/core'
import shuffle from 'lodash/shuffle'
import { useRouter } from 'next/router'
import React from 'react'

import { useDialog } from 'components/DialogsContext'
import { useLanguageContext } from 'components/LanguageContext'
import ShareSocial from 'components/ShareSocial'
import StyledImage from 'components/StyledImage'
import parseHtml from 'components/parseHtml'
import slugify from 'components/slugify'
import SimpleDialog from '../SimpleDialog'
import { badges, badgeImages } from './badges'

const useStyles = makeStyles(theme => ({
  badgeContainer: {
    marginTop: 40,
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px 0px 20px',
    },
    [theme.breakpoints.down('375')]: {
      justifyContent: 'flex-start',
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0px',
    },
  },
  badgeIcon: {
    zIndex: 1,
    marginRight: 10,
    cursor: 'pointer',
  },
  horizontalLine: {
    width: '100%',
    borderBottom: '1px solid #EDF3FC',
    position: 'absolute',
    top: 30,
    left: 0,
    zIndex: 0,
  },
  contentContainer: {
    marginTop: 15,
  },
  contentTitle: {
    color: ({ color }) => color,
  },
  contentText: {
    marginTop: 15,
  },
  root: {
    padding: 20,
    [theme.breakpoints.down('380')]: {
      padding: 5,
    },
  },
  shareIcon: {
    'width': 60,
    'height': 60,
    'border': '1px solid',
    'borderRadius': 35,
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'backgroundColor': 'white',
    'borderColor': ({ color }) => color,
    '&:hover': {
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
      transition: 'all 200ms',
    },
  },
  tooltip: {
    maxWidth: 185,
  },
  alert: {
    'marginTop': 30,
    'cursor': 'pointer',
    'transition': 'all 200ms',
    '&:hover': {
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
      transition: 'all 200ms',
    },
  },
}))

function getTipBadges(tip) {
  const { join_tip2category } = tip

  const tipCategories = join_tip2category
    ? [join_tip2category.id, join_tip2category.join_parentcategory.id]
    : []

  const tipBadges = badges.filter(badge => {
    const {
      completion: { category },
    } = badge
    return (
      tip[category] || tipCategories.includes(category) || category === null
    )
  })

  return shuffle(tipBadges).slice(0, 3)
}

function ShareIcon({ color }) {
  const classes = useStyles({ color })
  return (
    <Box className={classes.shareIcon}>
      <SvgIcon style={{ color }} viewBox="0 0 35 30" fontSize="large">
        <path d="M20.4173 21.7581V17.4996C20.4173 16.6942 19.7643 16.0412 18.959 16.0412C12.0316 16.0412 6.4825 20.2599 3.28136 23.4316C5.58195 10.4264 18.3647 10.2077 18.959 10.2077C19.7643 10.2077 20.4173 9.55469 20.4173 8.7493V4.49083L31.2093 13.1244L20.4173 21.7581ZM19.8704 0.319862C19.2415 -0.18328 18.3238 -0.0819224 17.8203 0.547004C17.6136 0.805502 17.5006 1.12635 17.5006 1.4574V7.4003C13.0562 7.91839 0 10.8749 0 27.7083C0 28.3426 0.409805 28.9041 1.01357 29.0974C1.15723 29.144 1.30744 29.167 1.45838 29.1666C1.93017 29.167 2.37315 28.9388 2.64696 28.5545C2.70894 28.4666 8.89977 19.9351 17.5006 19.0345V24.7915C17.5009 25.5969 18.1543 26.2495 18.96 26.2488C19.2907 26.2488 19.6119 26.1361 19.8704 25.929L34.4542 14.262C35.0824 13.7581 35.1831 12.8404 34.6792 12.2119C34.6128 12.1291 34.5374 12.0536 34.4542 11.9869L19.8704 0.319862Z" />
      </SvgIcon>
    </Box>
  )
}

function BadgeIconButton({ ...badge }) {
  const { openDownloadAppDialog } = useDialog()
  const { isSpanish } = useLanguageContext()
  const classes = useStyles()

  const badgeTitle = isSpanish ? badge.name_es : badge.name

  const openDownloadDialog = React.useCallback(() => {
    openDownloadAppDialog([
      badgeTitle,
      isSpanish ? badge.teaser_es : badge.teaser,
    ])
  }, [badge, isSpanish, badgeTitle, openDownloadAppDialog])

  return (
    <StyledImage
      key={badge.key}
      src={badgeImages[badge.key].default.src}
      alt={badgeTitle}
      containerClass={classes.badgeIcon}
      width={60}
      height={60}
      onClick={openDownloadDialog}
    />
  )
}

const TipDialog = React.forwardRef(({}, ref) => {
  const router = useRouter()
  const dialogRef = React.useRef()
  const { openDownloadAppDialog, openTipTermDialog } = useDialog()
  const { i18n, isSpanish, language } = useLanguageContext()
  const [tip, setTip] = React.useState({})

  const open = React.useCallback(tip => {
    setTip(tip)
    dialogRef.current.open()
  }, [])

  const close = React.useCallback(() => {
    router.push('/', undefined, {
      locale: language,
      shallow: true,
    })
    dialogRef.current.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  React.useImperativeHandle(ref, () => ({
    open,
    close,
  }))

  const onLinkPress = React.useCallback(
    e => {
      const {
        target: { href: url },
      } = e

      if (url && url.includes('term://')) {
        e.preventDefault()
        const term = url.replace('term://', '')
        openTipTermDialog(term)
      }
    },
    [openTipTermDialog]
  )

  const classes = useStyles({ color: tip.color })

  const shareText = `${i18n.tip.shareMsg} ${isSpanish ? tip.tip_es : tip.tip}`

  const shareUrl = React.useMemo(() => {
    if (!tip.id) {
      return ''
    }

    const title = isSpanish ? tip.tip_es : tip.tip

    return `tips/${tip.id}/${slugify(title)}`
  }, [tip, isSpanish])

  return (
    <SimpleDialog ref={dialogRef} onClose={close}>
      <Box className={classes.root}>
        <Typography variant="h2" color="textPrimary">
          {isSpanish ? tip.tip_es : tip.tip}
        </Typography>
        <Box className={classes.badgeContainer}>
          {getTipBadges(tip).map(BadgeIconButton)}
          <ShareSocial
            top={10}
            left={5}
            shareText={shareText}
            shareUrl={shareUrl}
          >
            <ShareIcon color={tip.color} />
          </ShareSocial>
          <Box className={classes.horizontalLine}></Box>
        </Box>
        <Box className={classes.contentContainer}>
          <Typography variant="h2" className={classes.contentTitle}>
            {i18n.tip.why}
          </Typography>
          <Typography
            variant="h6"
            className={classes.contentText}
            onClick={onLinkPress}
          >
            {parseHtml(isSpanish ? tip.why_es : tip.why)}
          </Typography>
        </Box>
        <Alert
          className={classes.alert}
          severity="info"
          onClick={openDownloadAppDialog}
        >
          {i18n.tip.downloadApp}
        </Alert>
      </Box>
    </SimpleDialog>
  )
})

TipDialog.displayName = 'TipDialog'

export default TipDialog
