import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import { Close as CloseDialogIcon } from '@material-ui/icons'
import React from 'react'

import { useLanguageContext } from 'components/LanguageContext'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 25,
    width: 700,
    minWidth: 700,
    height: ({ height }) => height || 700,
    transition: 'all 200ms',

    [theme.breakpoints.down('sm')]: {
      width: 500,
      minWidth: 0,
    },
  },
  dialogTitle: {
    padding: 5,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    padding: 16,
    [theme.breakpoints.down('xs')]: {
      padding: 6,
    },
  },
  dialogContentInnerContainer: {
    overflow: 'auto',
    height: '100%',
    paddingRight: 10,
  },
}))

const SimpleDialog = React.forwardRef(
  ({ height, children, onClose, ...props }, ref) => {
    const { i18n } = useLanguageContext()
    const [isOpen, setIsOpen] = React.useState(false)
    const classes = useStyles({ height })

    const open = React.useCallback(() => {
      setIsOpen(true)
    }, [])

    const close = React.useCallback(() => {
      setIsOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
      open,
      close,
    }))

    return (
      <Dialog
        {...props}
        open={isOpen}
        onClose={onClose || close}
        classes={{ paperScrollPaper: classes.root }}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <IconButton
            id="dialogClose"
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose || close}
          >
            <CloseDialogIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Box className={classes.dialogContentInnerContainer}>{children}</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose || close} color="primary">
            {i18n.dialog.close}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

SimpleDialog.displayName = 'SimpleDialog'

export default SimpleDialog
